<template>
  <section
    id="stocks"
    :class="{ stocks: true, dark: !content.stocks_bg_style }"
  >
    <div class="stocks__left">
      <div class="stocks__content">
        <div
          class="stocks__title"
          v-if="content.stocks_title"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          v-html="content.stocks_title"
        ></div>
        <div
          class="stocks__subtitle"
          v-if="content.stocks_desc"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          v-html="content.stocks_desc"
        ></div>
        <Timer
          parent-class="stocks__timer"
          :timer="content.stocks_timer"
          data-aos="custom-appearance"
          data-aos-duration="1000"
        />
        <form
          class="stocks__form"
          action="#"
          data-aos="custom-appearance"
          data-aos-duration="1000"
        >
          <p class="stocks__field">
            <Label parent-class="stocks__label label_color__dark">Имя</Label>
            <span class="input_wrapper">
              <InputText
                parent-class="stocks__input input-text__dark"
                type="text"
                name="stocks-name"
                v-model="dataForm.your_name"
              />
            </span>
          </p>
          <p :class="errors ? 'stocks__field error' : 'stocks__field'">
            <Label parent-class="stocks__label label_color__dark">{{
              errors ? "* Поле “Телефон” не указано" : "* Телефон"
            }}</Label>
            <span class="input_wrapper">
              <InputText
                parent-class="stocks__input input-text__dark"
                name="stocks-phone"
                v-input-mask
                v-model="dataForm.your_phone"
                type="tel"
                placeholder="+ 7 ("
              />
            </span>
          </p>
          <Button
            parent-class="stocks__button button__dark"
            v-on:click.prevent="submitForm"
            v-if="content.stocks_btn"
            >{{ content.stocks_btn }}</Button
          >
          <p class="stocks__politics">
            {{ content.stocks_politics }}
          </p>
        </form>
      </div>
    </div>
    <div class="stocks__right">
      <img
        class="stocks__img"
        :src="$mediaWp(content.stocks_id_img, 'stocks')"
        alt=""
        data-aos="custom-img"
        data-aos-duration="1000"
      />
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";
import Timer from "@/components/block/TimerApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { validation } from "@/helpers/index.js";

export default {
  components: {
    Button,
    Timer,
    InputText,
    Label,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.stocks,
      dataForm: {
        id: 1035,
        your_name: "",
        your_phone: "",
      },
      errors: false,
    };
  },
  methods: {
    submitForm() {
      this.errors = false;

      if (validation(this.dataForm.your_phone.length, 18)) {
        if (this.content.stocks_btn_metka) {
          this.$metrika.reachGoal(this.content.stocks_btn_metka);
        }

        this.$store.dispatch("submitForm", this.dataForm);

        this.dataForm = {
          id: 1035,
          your_name: "",
          your_phone: "",
        };
      } else {
        this.errors = true;
      }
    },
  },
};
</script>

<style>
.stocks {
  display: flex;
  justify-content: center;
  min-height: 928px;
  margin-bottom: 170px;

  background-color: var(--backgraund-site-white);
}

.stocks.dark {
  background-color: var(--backgraund-site-dark);
}

.stocks__left {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: calc(50% - 15px);
  padding: 110px 120px 110px 0;
}

.stocks__content {
  width: 569px;
}

.stocks__title {
  margin-bottom: 8px;

  font-family: var(--title3-fonts-family);
  font-size: var(--title3-fonts-size);
  font-weight: 400;
  line-height: var(--title3-line-height);
  color: var(--title3-color-white);
}

.dark .stocks__title {
  color: var(--title3-color-dark);
}

.stocks__subtitle {
  padding-right: 120px;
  margin-bottom: 60px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  color: var(--subtitle3-color-white);
}

.dark .stocks__subtitle {
  color: var(--subtitle3-color-dark);
}

.stocks__timer {
  margin-bottom: 70px;
}

.stocks__field {
  width: 330px;
  margin-bottom: 15px;
}

.stocks__field:nth-child(2) {
  margin-bottom: 35px;
}

.stocks__label {
  display: block;
  margin-left: 10px;
  margin-bottom: 10px;
}

.stocks__button {
  width: 330px;
  margin-bottom: 28px;
}

.error .stocks__politics-text {
  color: #d92641;
}

.stocks__right {
  position: relative;

  flex-grow: 1;

  overflow: hidden;
}

.stocks__img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;

  animation: increase-stock 30s infinite ease-in-out alternate;
}

@keyframes increase-stock {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.stocks__politics {
  font-family: var(--subtitle4-fonts-family);
  font-size: var(--subtitle4-fonts-size);
  color: var(--subtitle4-color-white);
}

.dark .stocks__politics {
  color: var(--subtitle4-color-dark);
}

@media screen and (max-width: 1599.98px) {
  .stocks {
    min-height: 728px;
    margin-bottom: 150px;
  }

  .stocks__left {
    padding: 100px 90px 100px 0;
  }

  .stocks__content {
    width: 430px;
  }

  .stocks__title {
    margin-bottom: 12px;

    font-size: 40px;
    line-height: 120%;
  }

  .stocks__subtitle {
    padding-right: 0;
    margin-bottom: 50px;

    font-size: 12px;
  }

  .stocks__timer {
    margin-bottom: 50px;
  }

  .stocks__field {
    width: 276px;
  }

  .stocks__field:nth-child(2) {
    margin-bottom: 30px;
  }

  .stocks__button {
    width: 276px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1199.98px) {
  .stocks {
    min-height: 645px;
    margin-bottom: 130px;
  }

  .stocks__left {
    padding: 80px 83px 80px 0;
  }

  .stocks__content {
    width: 384px;
  }

  .stocks__title {
    margin-bottom: 10px;

    font-size: 32px;
  }

  .stocks__subtitle {
    margin-bottom: 45px;
  }

  .stocks__timer {
    margin-bottom: 45px;
  }

  .stocks__field {
    width: 248px;
  }

  .stocks__field:nth-child(2) {
    margin-bottom: 25px;
  }

  .stocks__button {
    width: 248px;
  }
}

@media screen and (max-width: 991.98px) {
  .stocks {
    min-height: 591px;
    margin-bottom: 110px;
  }

  .stocks__left {
    width: calc(50% + 44px);
    padding: 80px 34px 80px 0;
  }

  .stocks__title {
    font-size: 28px;
  }

  .stocks__subtitle {
    font-size: 14px;
  }

  .stocks__content {
    width: 306px;
  }

  .stocks__timer {
    margin-bottom: 30px;
  }

  .stocks__field {
    width: 286px;
  }

  .stocks__button {
    width: 286px;
  }
}

@media screen and (max-width: 767.98px) {
  .stocks {
    flex-direction: column;
    min-height: auto;
    margin-bottom: 90px;
  }

  .stocks__left {
    justify-content: center;
    width: 100%;
    padding: 70px 0;
  }

  .stocks__content {
    width: 290px;
  }

  .stocks__title {
    font-size: 26px;
  }

  .stocks__subtitle {
    margin-bottom: 30px;
  }

  .stocks__field {
    width: 290px;
  }

  .stocks__button {
    width: 290px;
  }

  .stocks__right {
    position: relative;
  }

  .stocks__right::after {
    content: "";
    display: block;
    padding-top: 81.25%;
  }

  .stocks__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
</style>

<template>
  <section class="offer3">
    <div class="offer3__content">
      <div class="container">
        <div
          class="offer3__title"
          v-if="content.offer_1_title"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          v-html="content.offer_1_title"
        ></div>
        <div
          class="offer3__description"
          v-if="content.offer_1_desc"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-delay="100"
          v-html="content.offer_1_desc"
        ></div>
        <div
          class="offer3__button-wrapper"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          <Button
            parent-class="offer3__button"
            v-on:click="openPopup()"
            v-if="content.offer_1_button"
            >{{ content.offer_1_button }}</Button
          >
        </div>
      </div>
    </div>
    <div
      class="offer3__img"
      v-if="content.offer_1_bg_img"
      data-aos="custom-img"
      data-aos-duration="1200"
      data-aos-delay="1000"
    >
      <img
        class="offer3__img-file"
        :src="$mediaWp(content.offer_1_bg_img)"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    Button,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.offer3,
    };
  },
  methods: {
    openPopup() {
      if (this.content.offer_1_button_metka) {
        this.$metrika.reachGoal(this.content.offer_1_button_metka);
      }

      this.$store.commit("updateOrderPopup", {
        status: true,
        data: this.content.offer_1_popup,
        name: null,
      });
    },
  },
};
</script>

<style>
.offer3 {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 175px;

  background-color: #ffffff;
}

.offer3__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 723px;
  padding-top: 105px;
  padding-bottom: 40px;

  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offer3__title {
  width: 1170px;
  margin-bottom: 40px;

  font-family: var(--title1-fonts-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--title1-fonts-size);
  line-height: var(--title1-line-height);
  color: var(--title1-color-white);
}

.offer3__description {
  max-width: 565px;
  width: 100%;
  margin-left: 60px;
  margin-bottom: 30px;

  font-family: var(--subtitle1-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle1-fonts-size);
  line-height: var(--subtitle1-line-height);
  color: var(--subtitle1-color-white);
}

.offer3__button {
  margin-left: 60px;
}

.offer3__img {
  width: 100%;
  height: 665px;
}

.offer3__img-file {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

@media (max-width: 1919.98px) {
  .offer3__title {
    font-size: 64px;
  }
}

@media (max-width: 1599.98px) {
  .offer3 {
    margin-bottom: 150px;
  }

  .offer3__content {
    height: 635px;
    padding-top: 71px;
    padding-bottom: 30px;
  }

  .offer3__title {
    width: 845px;
    margin-bottom: 30px;

    font-size: 48px;
  }

  .offer3__description {
    max-width: 475px;
    margin-left: 60px;
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 160%;
  }

  .offer3__button {
    margin-left: 60px;
  }

  .offer3__img {
    height: 565px;
  }
}

@media (max-width: 1199.98px) {
  .offer3 {
    margin-bottom: 130px;
  }

  .offer3__content {
    height: 535px;
    padding-top: 71px;
    padding-bottom: 20px;
  }

  .offer3__title {
    width: 600px;
    margin-bottom: 20px;

    font-size: 36px;
  }

  .offer3__button {
    margin-left: 50px;
  }

  .offer3__img {
    height: 465px;
  }
}

@media (max-width: 991.98px) {
  .offer3 {
    margin-bottom: 110px;
  }

  .offer3__content {
    height: 435px;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .offer3__title {
    margin-bottom: 25px;

    font-size: 32px;
  }

  .offer3__description {
    max-width: 388px;
    margin-left: 0;
    margin-bottom: 20px;

    font-size: 14px;
  }

  .offer3__button {
    margin-left: 0;
  }

  .offer3__img {
    height: 500px;
  }
}

@media (max-width: 767.98px) {
  .offer3 {
    margin-bottom: 70px;
  }

  .offer3__content {
    padding-bottom: 10px;
  }

  .offer3__title {
    width: 100%;
    margin-bottom: 20px;

    font-size: 28px;
  }

  .offer3__description {
    max-width: none;
  }

  .offer3__button {
    margin-left: 0;
  }

  .offer3__img {
    height: 300px;
  }
}
</style>
